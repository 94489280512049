import React, { useState, Suspense, lazy } from "react";
import { graphql } from "gatsby";
import * as b from "../components/bootstrap.module.css";
import Icon from "../components/icon.tsx";
import Thumbnail from "../components/thumbnail";
import Out from "../components/out-product";
import Translate from "../components/translate";
import Breadcrumbs from "../components/breadcrumbs";
import Layout from "../components/layout";

import BaseHead from '../components/base-head';
import siteMetdata from "../utils/site-metadata";

export const Head = ({ ...props }) => {
  const name = props.data?.mysqlLocalfirms?.name?.split(' -')[0]?.trim();
  const geoName = props.data?.mysqlLocalfirms?.geo_name;
  const count = (props.data?.allMysqlLocalfirms?.nodes?.length || 0) + 1;

  const title = name && geoName 
    ? `${name} - ${geoName} - informacje o placówkach | akredo.pl` 
    : `${name} - informacje o placówkach | akredo.pl`;
  const description = name && geoName 
    ? `${name} w ${geoName} ma ${count} placówek. Sprawdź adresy, godziny otwarcia i ofertę lokalnych przedstawicieli ${name} w ${geoName}.` 
    : `Sprawdź adresy, godziny otwarcia i ofertę lokalnych przedstawicieli ${name}.`;
  
  return (
    <BaseHead 
      {...props} 
      title={title} 
      description={description}
    >
      {props?.pageContext?.mysqlId && <meta property="og:image" content={`${siteMetdata.domain}/social-media/localfirm/${props.pageContext.mysqlId}.png`} /> }
    </BaseHead>
  );
};

const OpenStreetMap = lazy(() => import('../components/open-street-map'));

const Localfirms = ({
  data: { mysqlLocalfirms, allMysqlLocalfirms }, 
  pageContext : { breadcrumbs },
  location: { pathname },
}) => {
  const {
    address_city,
    address_code,
    address_no,
    address_street,
    phone,
    name,
    mysqlImage,
    latitude,
    longitude,
    atm,
    cdm,
    checkoutService,
    disabledPeople,
    geo_name,
  } = mysqlLocalfirms;

  const [isOpen, setIsOpen] = useState(false);
  const firmName = name.split(' -')[0];

  const [isActive, setIsActive] = useState(null);

  const downloadMapComponent = () => {
    setIsActive(true);
  };
  
  return (
    <Layout pathname={pathname}>
      <div className={["local-firms-header",'intro-products2', b.dFlex, b.justifyContentCenter,].join(" ")}>
      </div>
      <div className={[b.container,'breadcrumbs-mobile-2',b.py3,b.ptLg5,"scroll-inner"].join(' ')}>
        <Breadcrumbs items={breadcrumbs} />
      </div>
      <article className={"firm-page"}>
        <div className={["local-firm-page", "blog-content", "post-content",'local-firms-w-map',b.pb4].join(" ")}>
          <div className={b.container}>
            <div className={b.row}>
              <div className={b.colLg8}>
                <h1 className={["big-title", b.mt3,b.mtLg4].join(" ")}>{firmName} - {geo_name}</h1>
                <p className={['font-size-20',b.pb3,'blackToWhite',b.mb3].join(' ')}>
                  {firmName} ma w {geo_name} {allMysqlLocalfirms.nodes.length + 1} placówek. W każdej z nich uzyskasz informacje o aktualnej ofercie banku i pomoc w rozwiązaniu problemów związanych z obsługą klienta. Sprawdź listę czynnych banków i znajdź najbliższą lokalizację Twojego banku!
                </p>
                
                <div className="map-wrapper" style={{ position: 'relative' }}>
                  {!isActive && <button 
                    style={{ 
                      position: 'absolute', 
                      top: 0, 
                      right: 0, 
                      bottom: 0, 
                      left: 0,
                      border: 0,
                      background: 'transparent',
                      fontSize: '22px',
                      cursor: 'pointer',
                    }} 
                    onClick={downloadMapComponent} 
                  >
                    Kliknij, aby załadować mapę
                  </button>}

                  {isActive && (
                    <Suspense fallback={<></>}>
                      <OpenStreetMap longitude={longitude} latitude={latitude} localfirms={allMysqlLocalfirms.nodes} />
                    </Suspense>
                  )}
                </div>
                
                <div className={[b.colMd,isOpen === -1 ? "firm-info-box-open" : "firm-info-box",b.mt3].join(" ")}>
                  <div className={[b.dFlex, b.justifyContentBetween].join(" ")} onClick={() => setIsOpen(isOpen === -1 ? -2 : -1)} role="button" tabIndex={0} onKeyDown={setIsOpen}>
                    <h2 className={['font-size-24',b.pt3,'blackToWhite'].join(" ")}>
                      {firmName} - {geo_name}, {address_street} {address_no}
                    </h2>
                    <button className={"btn-hidden"}>
                      {isOpen === -1 ? (
                        <Icon color="#21584D" size={35} icon="Angle-up" className={"firm-icons"} key="-1-up"/>
                      ) : (
                        <Icon color="#21584D" size={35} icon="Angle-down" className={"firm-icons"} key="-1-down"/>
                      )}
                    </button>
                  </div>
                  <div className={"firm-description"}>
                    <div className={b.row}>
                      <div className={[b.colMd6, b.mt3, b.mr3].join(" ")}>
                        <div className={b.dFlex}>
                          <Icon size={45} icon="HQ" className={[b.mr2, "icon-blog"].join(" ")}/>
                          <div className={b.justifyContentStart}>
                            <small><Translate tKey="Point address"/></small>
                            <div className={["local-firm-fs",'blueToWhite'].join(' ')}>
                              {address_street} {address_no}, {address_code} {address_city}
                            </div>
                          </div>
                        </div>
                      </div>
                      {phone&& 
                        <div className={[b.colMd5, b.mt3].join(" ")}>
                          <div className={b.dFlex}>
                            <Icon size={45} icon="Helpline-firm" className={[b.mr2, "icon-blog"].join(" ")}/>
                            <div className={b.justifyContentStart}>
                              <small><Translate tKey="Helpline"/></small>
                              <div className={"local-firm-fs"}>{phone}</div>
                            </div>
                          </div>
                        </div>
                      }
                    </div>
                    <div className={[b.row, b.mb3].join(" ")}>
                      <div className={[b.colMd3, b.mt3].join(" ")}>
                        <div className={b.dFlex}>
                          <Icon size={45} icon="Disabled" className={[b.mr2, "icon-blog"].join(" ")}/>
                          <div className={b.justifyContentStart}>
                            <small><Translate tKey="Support for people with disabilities"/></small>
                            { disabledPeople === 1 ? <div className={"local-firm-yes"}>tak</div>
                            : <div className={"local-firm-no"}>nie</div> }
                          </div>
                        </div>
                      </div>

                      <div className={[b.colMd3, b.mt3].join(" ")}>
                        <div className={b.dFlex}>
                          <Icon size={40} icon="Checkout-service" className={[b.mr2, "icon-blog"].join(" ")}/>
                          <div className={b.justifyContentStart}>
                            <small><Translate tKey="Cash handling"/></small>
                            { checkoutService === 1 ? <div className={"local-firm-yes"}>tak</div>
                            : <div className={"local-firm-no"}>nie</div> }
                          </div>
                        </div>
                      </div>

                      <div className={[b.colMd2, b.mt3].join(" ")}>
                        <div className={b.dFlex}>
                          <Icon size={40} icon="Cash-deposit-machine" className={[b.mr2, "icon-blog"].join(" ")}/>
                          <div className={b.justifyContentStart}>
                            <small><Translate tKey="Cash deposit Machine"/></small>
                            { cdm === 1 ? <div className={"local-firm-yes"}>tak</div>
                            : <div className={"local-firm-no"}>nie</div> }
                          </div>
                        </div>
                      </div>

                      <div className={[b.colMd2, b.mt3].join(" ")}>
                        <div className={b.dFlex}>
                          <Icon size={40} icon="Atm" className={[b.mr2, "icon-blog"].join(" ")}/>
                          <div className={b.justifyContentStart}>
                            <small><Translate tKey="ATM"/></small>
                            { atm === 1 ? <div className={"local-firm-yes"}>tak</div>
                            : <div className={"local-firm-no"}>nie</div> }
                          </div>
                        </div>
                      </div>
                    </div>
                    <Out className={[b.btn, "btn-success", "btn-cta", b.mt2,b.fontWeightNormal,b.textWhite,b.justifyContentCenter,b.alignItemsCenter,b.dFlex].join(" ")} item={mysqlLocalfirms} defaultDescription="Contact request" defaultIcon="Pen" />
                  </div>
                </div>
                {allMysqlLocalfirms.nodes.map((node, i) => (
                  <div className={[b.colMd, isOpen === i ? "firm-info-box-open" : "firm-info-box",b.mt3].join(" ")} key={i}>
                    <div className={[b.dFlex, b.justifyContentBetween].join(" ")} onClick={() => setIsOpen(isOpen === i ? i - 11 : i)} role="button" tabIndex={0} onKeyDown={setIsOpen}>
                      <h2 className={['font-size-24',b.pt3,'blackToWhite'].join(" ")}>
                        {firmName} - {geo_name}, {node.address_street} {node.address_no}
                      </h2>
                      <button className={"btn-hidden"}>
                        {isOpen === i ? (
                          <Icon color="#21584D" size={35} icon="Angle-up" className={"firm-icons"} key={`${i}-up`}/>
                        ) : (
                          <Icon color="#21584D" size={35} icon="Angle-down" className={"firm-icons"} key={`${i}-down`}/>
                        )}
                      </button>
                    </div>
                    <div className={"firm-description"}>
                      <div className={b.row} key={i}>
                        <div className={[b.colMd6, b.mt3, b.mr3].join(" ")}>
                          <div className={b.dFlex}>
                            <Icon size={45} icon="HQ" className={[b.mr2, "icon-blog"].join(" ")}/>
                            <div className={b.justifyContentStart}>
                              <small><Translate tKey="Point address"/></small>
                              <div className={["local-firm-fs",'blueToWhite'].join(' ')}>
                                {node.address_street} {node.address_no}, {node.address_code} {node.address_city}
                              </div>
                            </div>
                          </div>
                        </div>
                        
                        {node.phone && 
                          <div className={[b.colMd5, b.mt3].join(" ")}>
                            <div className={b.dFlex}>
                              <Icon size={45} icon="Helpline-firm" className={[b.mr2, "icon-blog"].join(" ")}/>
                              <div className={b.justifyContentStart}>
                                <small><Translate tKey="Helpline"/></small>
                                <div className={"local-firm-fs"}>
                                  {node.phone}
                                </div>
                              </div>
                            </div>
                          </div>
                        }
                      </div>
                      <div className={[b.row, b.mb3].join(" ")}>
                        <div className={[b.colMd3, b.mt3].join(" ")}>
                          <div className={b.dFlex}>
                            <Icon size={45} icon="Disabled" className={[b.mr2, "icon-blog"].join(" ")}/>
                            <div className={b.justifyContentStart}>
                              <small><Translate tKey="Support for people with disabilities"/></small>
                              { node.disabledPeople === 1 ? <div className={"local-firm-yes"}>tak</div>
                                : <div className={"local-firm-no"}>nie</div> }
                            </div>
                          </div>
                        </div>

                        <div className={[b.colMd3, b.mt3].join(" ")}>
                          <div className={b.dFlex}>
                            <Icon size={40} icon="Checkout-service" className={[b.mr2, "icon-blog"].join(" ")}/>
                            <div className={b.justifyContentStart}>
                              <small><Translate tKey="Cash handling"/></small>
                              { node.checkoutService === 1 ? <div className={"local-firm-yes"}>tak</div>
                                : <div className={"local-firm-no"}>nie</div> }
                            </div>
                          </div>
                        </div>

                        <div className={[b.colMd2, b.mt3].join(" ")}>
                          <div className={b.dFlex}>
                            <Icon size={40} icon="Cash-deposit-machine" className={[b.mr2, "icon-blog"].join(" ")}/>
                            <div className={b.justifyContentStart}>
                              <small><Translate tKey="Cash deposit Machine"/></small>
                              { node.cdm === 1 ? <div className={"local-firm-yes"}>tak</div>
                              : <div className={"local-firm-no"}>nie</div> }
                            </div>
                          </div>
                        </div>

                        <div className={[b.colMd2, b.mt3].join(" ")}>
                          <div className={b.dFlex}>
                            <Icon size={40} icon="Atm" className={[b.mr2, "icon-blog"].join(" ")}/>
                            <div className={b.justifyContentStart}>
                              <small><Translate tKey="ATM"/></small>
                              { node.atm === 1 ? <div className={"local-firm-yes"}>tak</div>
                               : <div className={"local-firm-no"}>nie</div> }
                            </div>
                          </div>
                        </div>
                      </div>
                      <Out className={[b.btn, "btn-success", "btn-cta", b.mt2,b.fontWeightNormal,b.textWhite,b.justifyContentCenter,b.alignItemsCenter,b.dFlex].join(" ")}
                        item={mysqlLocalfirms} defaultDescription="Contact request" defaultIcon="Pen"/>
                    </div>
                  </div>
                ))}
              </div>
              <div className={[b.colLg4,b.mt3,b.mtLg5].join(' ')}>
                {mysqlImage && (
                  <>
                    <div className={"local-firm-img-box"}>
                      <div className='bank-padding-img'>
                        <Thumbnail image={mysqlImage} alt={name} />
                      </div>
                    </div>
                  </>
                )}
                <div className={"local-firm-box"}>
                  <h3><Translate tKey="Establishments in"/> {address_city}</h3>
                  <ul className='dark-theme-listing'>
                    <li className={['post-text',b.pt1].join(' ')}>
                      {firmName} {address_street} {address_no}
                    </li>
                    {allMysqlLocalfirms.nodes.map((node, i) => (
                      <li className={['post-text',b.pt1].join(' ')} key={i}>
                        {firmName} {node.address_street} {node.address_no}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
    </Layout>
  );
};

export default Localfirms;

export const query = graphql`
  query QueryLocalfirms($mysqlId: Int!) {
    mysqlLocalfirms: mysqlLocalfirms(mysqlId: { eq: $mysqlId }) {
      address_city
      address_code
      address_no
      address_street
      phone
      name
      url
      latitude
      longitude
      geo_name
      atm
      cdm
      latitude
      longitude
      checkoutService
      geo_url
      disabledPeople
      mysqlImage {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
    allMysqlLocalfirms(filter: { parentId: { eq: $mysqlId } }) {
      nodes {
        address_city
        address_code
        address_no
        address_street
        phone
        name
        latitude
        longitude
        url
        disabledPeople
        geo_url
        atm
        cdm
        prefix_url
        specific_url
        url
        checkoutService
      }
    }
  }
`;
